<script lang="ts">
  import type { LanguageLocalization } from '../localization';
  import OrrickLogoSrc from './team-logos/orrick-logo.svg';

  export let ll: LanguageLocalization;

  const logos = [
    { src: OrrickLogoSrc, alt: 'Orrick, Herrington & Sutcliffe LLP' },
  ] as const satisfies readonly {
    src: string;
    alt: string;
  }[];
</script>

<section
  id="trusted-by"
  aria-labelledby="trusted-by-title"
  class="w-full pb-6 pt-12"
>
  <h2
    id="trusted-by-title"
    class="mb-8 px-2 text-center text-[2.8rem] font-semibold leading-tight
           md:mb-12 md:text-[3.625rem] xl:text-[4.25rem]"
  >
    {ll.trustedTitle}
  </h2>

  <div
    class="mx-auto flex max-w-screen-xl flex-wrap justify-evenly justify-items-center
           gap-x-16 p-8 pb-0 md:justify-evenly md:gap-x-8 md:px-8"
  >
    {#each logos as { src, alt }}
      <div
        class="flex aspect-square w-32 flex-shrink items-center md:w-36 xl:w-48"
      >
        <img {src} {alt} loading="lazy" />
      </div>
    {/each}
  </div>
</section>
