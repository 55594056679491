<script lang="ts" context="module">
  import Sam from '$lib/assets/portraits/Samuel_Tazartes.jpeg';
  import Alexandre from '$lib/assets/portraits/alexandre-512.jpeg';
  import Sebastien from '$lib/assets/portraits/sebastien.jpeg';
  import Tristan from '$lib/assets/portraits/tristan-512.jpeg';
  import easySrc from '$lib/assets/svg/landing-page/Easy-resized-min.png';
  import mainIllustrationSrc from '$lib/assets/svg/landing-page/Principale-resized-min.png';
  import quickSrc from '$lib/assets/svg/landing-page/Quick-resized-min.png';
  import schemaSrc from '$lib/assets/svg/landing-page/Schema.svg';
  import safeSrc from '$lib/assets/svg/landing-page/Secure-resized-min.png';
  import PomeLogo from '$lib/components/PomeLogo.svelte';
  import Slanted from '$lib/components/Slanted.svelte';
  import {
    DATA_PRIVACY_URL,
    TERMS_AND_CONDITIONS_URL,
    canonicalUrlForPath,
  } from '$lib/globals';
  import TryItNow from './TryItNow.svelte';
  import {
    defaultLocale,
    locales,
    localized,
    type Locale,
    type LocalizedString,
    type LocalizedStringWithDefault,
  } from './localization';
  import DocuSignLogoSrc from './sections/e-signature-provider-logos/docusign-logo.svg';

  // Links in navigation bar
  interface Link {
    label: LocalizedStringWithDefault;
    href: string;
    prefetch?: true | undefined;
    visibleSmall?: true | undefined;
  }
  const links: readonly Link[] = [
    { label: { en: 'Product', fr: 'Produit' }, href: '#product' },
    { label: { en: 'Try it now', fr: 'Essayer' }, href: '#try-now' },
    { label: { en: 'Contact' }, href: 'mailto:servicedesk@orrick.com' },
    {
      label: { en: 'Login' },
      href: '/generate',
      prefetch: true,
      visibleSmall: true,
    },
  ];

  // Product descriptions with images on alternating sides
  interface ProductDescription {
    title: LocalizedString;
    description: LocalizedString;
    image: { src: string; alt: LocalizedString };
  }
  const productDescriptionSections = [
    {
      title: { en: 'Quick and effective', fr: 'Rapide et efficace' },
      description: {
        en:
          'pome can generate hundreds of documents per minute and is designed to' +
          ' handle large quantities of material without affecting your computer.' +
          ' It also operates with Jinja2, a fast and powerful templating engine.' +
          ' Not only can it fill documents with the relevant information,' +
          ' it can also support complex logic.',
        fr:
          'pome peut générer des centaines de documents par minute et est conçu pour' +
          ' gérer de grandes quantités de contenu sans affecter votre ordinateur.' +
          ' Il fonctionne également avec Jinja2, un moteur de modélisation rapide et puissant.' +
          ' Non seulement il peut remplir les documents avec les informations pertinentes,' +
          ' mais il peut également prendre en charge une logique complexe.',
      },
      image: {
        src: quickSrc,
        alt: {
          en: 'A person running with documents.',
          fr: 'Une personne qui court avec des documents.',
        },
      },
    },
    {
      title: { en: 'Easy to use', fr: 'Facile à utiliser' },
      description: {
        en:
          'With its minimalistic interface and 3-step process, pome needs no learning time.' +
          ' It will drastically improve your reviewing and correcting process:' +
          ' instead of going over every single document, you only have to check' +
          ' and modify the template and the datasheet.',
        fr:
          "Avec son interface minimaliste et son processus en 3 étapes, pome n’a pas besoin de temps d'apprentissage." +
          ' Il améliorera considérablement votre processus de révision et de correction :' +
          ' au lieu de passer en revue chaque document, vous n’avez qu’à vérifier' +
          ' et modifier le modèle et la feuille de données.',
      },
      image: {
        src: easySrc,
        alt: {
          en: 'A person using pome on a computer.',
          fr: 'Une personne utilisant pome sur un ordinateur.',
        },
      },
    },
    {
      title: { en: 'Protects privacy', fr: 'Protège la vie privée' },
      description: {
        en:
          'We are well aware of the sensitive nature of the documents involved,' +
          ' which is why our Data Privacy Policy is up to the highest standards.' +
          ' Also, each document will be completely and immediately deleted after processing.',
        fr:
          'Nous sommes bien conscients de la nature sensible des documents impliqués,' +
          ' c’est pourquoi notre Politique de confidentialité des données est conforme aux normes les plus élevées.' +
          ' De plus, chaque document sera complètement et immédiatement supprimé après traitement.',
      },
      image: {
        src: safeSrc,
        alt: {
          en: 'A person holding a key with two hands.',
          fr: 'Une personne tenant une clé à deux mains.',
        },
      },
    },
  ] as const satisfies readonly ProductDescription[];
</script>

<script lang="ts">
  import { page } from '$app/stores';
  import { isDefaultLocale } from './localization';
  import Testimonials from './sections/Testimonials.svelte';
  import TrustedBy from './sections/TrustedBy.svelte';

  export let lang: Locale = 'en';

  $: ll = localized[lang];
</script>

<svelte:head>
  <!-- On the default locale, tell search engines that the canonical URL is actually at the root -->
  {#if isDefaultLocale(lang)}
    <link rel="canonical" href={canonicalUrlForPath('/').href} />
  {/if}

  <!-- Tell search engines how to access each locale -->
  {#each locales as locale}
    {@const path = isDefaultLocale(locale) ? '/' : `/${locale}`}
    <link
      rel="alternate"
      hreflang={locale}
      href={canonicalUrlForPath(path).href}
    />
  {/each}
  <link
    rel="alternate"
    hreflang="x-default"
    href={canonicalUrlForPath('/').href}
  />
</svelte:head>

<div {lang} class="relative z-0 min-h-screen w-full pb-20 font-normal">
  <!-- Top section -->
  <div class="mx-auto max-w-screen-xl px-2 md:px-6 xl:px-6">
    <header class="flex flex-row items-center justify-between pt-4 md:pt-8">
      <a
        class="flex flex-shrink-0 items-center space-x-2 text-xl"
        href={$page.url.pathname}
      >
        <PomeLogo />
        <span class="logo-typo text-to-crop text-3xl">pome.gr</span>
      </a>
      <!-- pr-20 gives trompe-loeil that main illustration is going over -->
      <nav class="flex space-x-5 font-semibold md:pr-20 lg:space-x-12">
        {#each links as { href, label, prefetch, visibleSmall }}
          {@const labelLocalized = label[lang] ?? label[defaultLocale]}
          <a
            {href}
            data-sveltekit-preload-code={prefetch ? 'hover' : 'off'}
            class="navlink hover:text-pome-salmon"
            class:hide-link={!visibleSmall}>{labelLocalized}</a
          >
        {/each}
      </nav>
    </header>

    <!-- Main callout -->
    <section
      class="relative flex flex-col items-center justify-center lg:items-start"
    >
      <!-- Title, subtitles and buttons -->
      <div
        class="mx-4 flex w-full max-w-[500px] flex-col items-center pt-12 lg:mx-0 lg:mb-28 lg:w-1/2 lg:items-start lg:pt-32 xl:mb-20 xl:pt-40"
      >
        <h1
          class="big-titles text-center font-bold leading-9 text-pome-light lg:text-left"
        >
          {ll.doItBetter}
        </h1>
        <h2
          class="big-subtitles mt-3 w-full text-center font-bold leading-8 text-pome-salmon lg:mt-5 lg:text-left lg:leading-9 xl:text-5xl"
        >
          {ll.generateThousandsOfDocuments}
        </h2>
        <!-- Buttons -->
        <div
          class=" flex items-center justify-start space-x-5 pt-5 sm:mb-4 md:mb-0 md:pt-6"
        >
          <a
            href="#try-now"
            class="plausible-event-name=Landing/ClickedTryItNow flex w-40 cursor-pointer items-center justify-center rounded-[10px] border-2 border-pome-light px-3 py-2 text-center font-semibold text-pome-light hover:bg-[#ffffff26]"
          >
            {ll.tryItNow}
          </a>
          <a
            href="/auth/signup"
            data-sveltekit-preload-code="viewport"
            class="flex w-40 cursor-pointer items-center justify-center rounded-[10px] bg-pome-red px-3 py-2 font-semibold text-pome-light hover:bg-pome-red-hover"
          >
            {ll.signUp}
          </a>
        </div>
      </div>
      <!-- Illustration when less than lg -->
      <div class="z-10 -mb-32 mt-2 lg:hidden">
        <img
          class="z-10 mt-8 w-[350px] sm:w-[400px]"
          src={mainIllustrationSrc}
          alt={ll.personHoldingAPomegranate}
        />
      </div>
      <!-- Illustration when lg -->
      <div
        class="absolute -right-4 top-16 z-10 hidden md:top-12 lg:block xl:-right-2"
      >
        <img
          class="lg:w-[500px] xl:w-[580px]"
          src={mainIllustrationSrc}
          alt={ll.personHoldingAPomegranate}
        />
      </div>
    </section>
  </div>

  <Slanted skewedExtraClasses="bg-white" withTopMargin>
    <!-- Drag. Drop. Generate. -->
    <section
      id="product"
      class="mx-auto my-8 flex w-full flex-col items-center text-center text-pome-dark"
    >
      <h2
        class="big-titles mt-16 flex flex-col items-center text-center font-bold leading-none md:mt-10 lg:mt-0"
      >
        <span>{ll.dragDrop}</span>
        <span><span class="text-pome-red">{ll.generate}</span>.</span>
      </h2>

      <p class="mt-5 px-4 text-lg md:mb-0 md:text-[22px]">
        {ll.pomeIsSuperEasyToUseL1}<br />
        {ll.pomeIsSuperEasyToUseL2}<br />
        {ll.pomeIsSuperEasyToUseL3}
      </p>

      <img
        src={schemaSrc}
        alt="Schema of pome combining documents"
        class="-mb-8 mt-10 w-[580px] px-4"
      />
    </section>
  </Slanted>

  <Slanted skewedExtraClasses="bg-[#E6E6E6]" withBottomMargin overlapTop>
    <!-- Drag. Drop. Generate. -->
    <section
      id="product-sign"
      class="mx-auto my-8 flex w-full flex-col items-center text-center text-pome-dark md:my-0"
    >
      <h2 class="big-titles font-bold leading-tight md:mt-10 lg:mt-0">
        <span class="text-pome-red">{ll.sign}</span>.
      </h2>

      <span
        class="my-12 block flex-col items-center px-4 text-lg md:flex md:text-[22px]"
      >
        <span>{ll.eSignatureIntegrationL1}</span>
        <span>{ll.eSignatureIntegrationL2}</span>
      </span>

      <!-- Logos -->
      <div
        class="flex w-full items-center justify-evenly gap-x-12 px-12 sm:w-auto sm:justify-center sm:gap-x-16 md:gap-x-20 lg:gap-x-24"
      >
        <img
          src={DocuSignLogoSrc}
          alt="DocuSign"
          loading="lazy"
          class="w-8 flex-1 sm:w-44 sm:flex-initial md:w-52"
        />
      </div>
    </section>
  </Slanted>

  <!-- Try it now widget -->
  <section id="try-now" aria-labelledby="try-now-title" class="py-8 md:py-4">
    <h2
      id="try-now-title"
      class="big-titles text-center font-semibold text-white"
    >
      {ll.tryItNowTitle}
    </h2>
    <TryItNow {lang} />
  </section>

  <!-- Product Descriptions -->

  <Slanted skewedExtraClasses="bg-white" withTopMargin withBottomMargin>
    <div class="mx-auto max-w-screen-xl px-2 md:px-6 xl:px-6">
      <section class="w-full pt-10 text-pome-dark">
        <h2
          class="big-titles my-12 mb-8 px-4 text-center font-semibold leading-tight md:mb-12"
        >
          <span class="text-pome-red">{ll.efficient}</span>,
          <span class="text-pome-red">{ll.timeSaving}</span>
          {ll.and}
          <span class="text-pome-red">{ll.reliable}</span>.
        </h2>

        <div class="flex flex-col items-center space-y-20 py-10 lg:space-y-0">
          {#each productDescriptionSections as { title, description, image: { src, alt } }, index}
            <!-- Title and Description -->
            <div
              class="flex flex-col items-center {index % 2 === 0
                ? 'flex-col-reverse md:flex-row'
                : 'flex-col-reverse md:flex-row-reverse md:space-x-reverse'} "
            >
              <div
                aria-labelledby="product-description-{index}"
                class="w-10/12 md:w-1/2"
              >
                <h3
                  id="product-description-{index}"
                  class="product-description-title mb-2 font-semibold leading-[1.15] md:mb-4"
                >
                  {title[lang]}
                </h3>
                <p
                  class="product-description-text leading-normal md:leading-relaxed"
                >
                  {description[lang]}
                </p>
              </div>
              <!-- Image -->
              <div
                class="flex w-full flex-col items-center md:w-1/2"
                class:mt-4={index === 2}
              >
                <img {src} alt={alt[lang]} />
              </div>
            </div>
          {/each}
        </div>
      </section>
    </div>
  </Slanted>

  <Testimonials {lang} {ll} />

  <!-- Trustees -->
  <Slanted
    class="w-full text-pome-dark"
    skewedExtraClasses="bg-white"
    withTopMargin
    withBottomMargin
  >
    <TrustedBy {ll} />
  </Slanted>

  <footer
    class="absolute bottom-2 flex w-full justify-center space-x-2 text-center text-sm"
  >
    <a
      href={DATA_PRIVACY_URL}
      class="hover:text-pome-salmon"
      target="_blank"
      rel="noreferrer">{ll.dataPrivacyNotice}</a
    >
    <span> · </span>
    <a
      href={TERMS_AND_CONDITIONS_URL}
      class="hover:text-pome-salmon"
      target="_blank"
      rel="noreferrer">{ll.termsAndConditions}</a
    >
  </footer>
</div>

<style lang="postcss">
  .navlink.hide-link {
    @apply hidden md:block;
  }

  .big-titles {
    @apply text-[2.8rem] md:text-[3.625rem] xl:text-[4.25rem];
  }

  .big-subtitles {
    @apply text-[2.2rem] md:text-[2.375rem];
  }

  .product-description-title {
    @apply text-[2rem];
  }

  .product-description-text {
    @apply text-[1.2rem] md:text-[1.375rem];
  }
</style>
